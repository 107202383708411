body::-webkit-scrollbar {
    width: 0.5em;
  }
   
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
body::-webkit-scrollbar-thumb {
    background-color: #4b4b4b;
    outline: 3px solid rgb(21, 21, 21);
  }
.navshadow{
  box-shadow:         0px 0px 16px 0px transparentize($color: #000000, $amount: 0.6);
  transition: top 0.4s;
}

.toolbar{
  height: fit-content;
  position: sticky;  
  z-index: 1;
  transition: top ease 0.5s;
  @media (max-width: 768px) {
    height: auto; 
    //background-color: #dfe4ea;
    margin-top: 0.5rem;
  }
}

.toolbar-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }

  .my-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius:30px;
    background-color:#3d3d3d;
    padding: 0.5rem 0rem 0.5rem 0rem;
    margin: 0.2rem 0rem 0.2rem 0rem;
    width: 100%;
    transition: ease-in-out 0.4s;
    @media (max-width: 768px) {
      width: auto;
      margin: 0rem;
      
    }
  }

  .my-button:hover{
    cursor: pointer;
    background-color: #808e9b;
    @media (max-width: 768px) {
      background-color:#3d3d3d;
    }
  }

  .my-button:active{
    background-color: #2ecc71;
  }

  .button-text{
    margin-right: 0.6rem;
    color: transparentize($color: #ffffff, $amount: 0.1);
    font-weight: 400;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: none;
      visibility: hidden;
    }
  }
}

.footer-logo{
  color:transparentize($color: #000000, $amount: 0.6);
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.1rem;
}

.nav-trans {
  transition: ease-in-out 0.2s;
}

.drop-section{
  //height: fit-content;
  //style={{ borderRadius: "10px", marginTop: "1rem", overflow: "hidden", backgroundColor: "#bdc3c7" }}
  background-color:#bdc3c7	; //	#f5f5f5 #bdc3c7
  border-radius: 10px;
  margin-top: 1rem;
  overflow: hidden;
  box-shadow: inset 2px 18px 30px 18px transparentize($color: #000000, $amount: 0.8);
}

.section-title{
  background-color:	#363636;
  padding: 0.8rem;
  box-shadow:         0px 0px 21px 0px transparentize($color: #000000, $amount: 0.4);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section-control-box{
  display: flex;
  flex-basis: content;;
}

.component-data{
  //style={{display:"flex", flexDirection:"row", alignItems:'center',justifyContent:"space-between", width:"100%"}}
  display: flex;
  flex-direction: row;
  //align-items: center;
  //justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: space-between;
    justify-content: start;
  }

  @media (min-width: 768px) {
    align-items: center;
    justify-content: space-between;
  }
}

.component-control{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  align-self: stretch;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: space-evenly;
  }

  .delete-control{
    display: flex;
    padding: 1rem;
    height: 100%;
    background-color:  #2d3436; //#2d3436
    align-self: stretch;
    transition: ease 0.3s;

  }
  .delete-control:hover,.delete-control:active{
    cursor: pointer;
    background-color: #ff3838;

  }
  .move-control{
    display: flex;
    padding: 1rem;
    height: 100%;
    background-color: #2d3436;
    align-self: stretch;
  }

  .move-control:active{
    background-color: #4b7bec;
  }

  .edit-control{
    display: flex;
    padding: 1rem;
    height: 100%;
    background-color:  #2d3436;
    align-self: stretch;
    transition: ease 0.3s;
  }
  .edit-control:hover,.edit-control:active{
    cursor: pointer;
    background-color: #ffa801;
  }
}

.field-placeholder{
  display: flex;
  margin: 2rem 1rem;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: transparentize($color: #ffffff, $amount: 0.7);
  color: transparentize($color: #ffffff, $amount: 0.7) ;
  font-weight: 800;
  cursor: pointer;
  text-align: center;
}

.center-view {
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0em;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #1a69fc;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 16px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 16px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 16px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 4px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 4.5px;
        top: 1.5px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      left:-1px;
      top:-1px;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .5));
    }
    &:checked {
      --s: .5;
    }
  }
}

.checkbox span, .radio span{
  vertical-align: top;
  margin-left: 0.5rem;
  line-height: 1rem;
}


.errorbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  height: 16%;
}

.errorContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  text-align: center;
  height: 84%;
}

$body-background-color :#dfe4ea;
$footer-background-color:#ffffff00;
$footer-padding: 0rem 0rem 2rem 0rem;
$panel-block-hover-background-color:#c7ecee;
$box-shadow:0 0.7em 1em -0.125em rgba(#000000, 0.16), 0 0px 0 1px rgba(#000000, 0.02);


@import '../node_modules/bulma/bulma.sass'